$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/kleininfrastrukturprojekte";
@import "styles/cookiebanner";
@import "~bootstrap/scss/bootstrap";
@import "styles/swiper/pagination";
@import "styles/swiper/slider";
//@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/application";
@import "styles/headings";
@import "styles/program";
@import "styles/footer";
@import "styles/text";
@import "styles/listing";
// @import "styles/slideshow/all";
@import "styles/accounts";
@import "styles/gallery/caption";


html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-size: 1.0rem;
  background: #f0f0f0;

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
    //font-size: 1.1rem;
  }
}

/* make room for the nav bar */
div.row.section[id] {
  padding-top: 70px;
  margin-top: -35px;
  margin-bottom: 2rem;
}

div.row.intro[id] {
  padding-top: 40px;
}
.hover\:text-secondary:hover {
  color: $secondary;
}

.min-h-75 {
  min-height: 40vh;
}

.h-20 {
  height: 6rem;
}

.h-10 {
  height: 3rem;
}

.relative {
  position: relative;
  overflow: hidden;
}

.w-20 {
  width: 20%;
}

.w-33 {
  width: 33.3333%;
}

.img-object-center {
  object-position: center center;
  object-fit: contain;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 5rem;
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, 0);
}

.py-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.9rem;
}

.text-gray-700 {
  color: $gray-700;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.leading-tight {
  line-height: 1.1;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}
.bg-gray-400 {
  background-color: $gray-400;
}

.modal.modal-fullscreen {
  padding-right: 0px !important;
}

.modal-fullscreen .close {
  display: inline-block;
  text-align: right;
  align-self: flex-end;
  padding-right: 1rem;
  margin: 0;
  margin-bottom: -1rem;
  font-size: 4rem;
  position: fixed;
  z-index: 1;
}

.header {
  //margin-top: 70px;
  //margin-bottom: 70px;
}

@media print {
  .header {
    display: none;
  }
}

.header__intro {
  font-size: 150%;
  text-transform: uppercase;
  font-weight: 200;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-family: $font-family-sans-serif;

  strong, b {
    font-weight: 900;
  }
}

.navbar-brand {
  font-family: $font-family-sans-serif;
  font-weight: 200;
  text-transform: uppercase;
}

.navbar.navbar-kleininfrastrukturprojekte {
  //height: 5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  @include media-breakpoint-up(lg) {
    font-size: 1.0rem;
    padding-right: 0;

    &.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .dropdown-menu {
      padding: 0.15rem 0;
      font-size: 1.0rem;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.2rem;

    &.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    .dropdown-menu {
      font-size: 1.2rem;
    }
  }

  .navbar-brand-logo {
    width: 6rem;
    position: relative;
    top: 0rem;
    left: 0rem;
  }

  @include media-breakpoint-up(lg) {
    .navbar-brand-logo {
      width: 8rem;
      top: 0rem;
      left: 0;
    }
  }
}

.badge {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.break-word {
  word-break: break-all;
}

.w-100\+30 {
  left: -15px;
  width: calc(100% + 30px);
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

[x-cloak] {
  display: none !important;
}

.absolute {
  position: absolute;
}

.transition {
  transition: opacity;
}
.duration-200 {
  transition-duration: 200ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.ease-in {
  transition-timing-function: ease-in;
}
.ease-out {
  transition-timing-function: ease-out;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.cursor-default {
  cursor: default;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rating-frame-label {
  border: 1px solid $primary;
  padding: 0 0.5rem;
  background: white;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & > span {
    padding: 0 0.5rem;
    text-overflow: ellipsis " [..]";
    overflow: hidden;
    height: 2.1rem;
  }
}

.rating-frame-header {
  height: 2.1rem;
  overflow: hidden;
}

.rating-frame-buttons {
  min-width: max-content;
}

.bg-list-funded {
  background: #f8fff9;
  border: 1px solid #b7ffc2 !important;
}

.bg-list-active {
  background: #e2f0e6;
  border: 1px solid $primary !important;
}

.bg-green-100 {
  background-color: $green-100;

  td, th {
    background-color: $green-100;
  }

  &:hover {
    background-color: $green-200;

    td, th {
      background-color: $green-200;
    }
  }
}

.col-0 {
  flex: 0 0 0%;
  max-width: 0;
  width: 0;
  overflow: hidden;
}

.fullscreen-toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.h-3 {
  height: 1rem;
}

.w-3 {
  width: 1rem;
}

.h-5 {
  height: 1.5rem;
}

.w-5 {
  width: 1.5rem;
}

.text-large {
  font-size: 1.15rem;
}

@media print {
  .print\:hidden {
    display: none;
  }
}
